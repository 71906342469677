import {
    addClass, arrayFrom, fetchFunc, isBuyOneClick,
    newFormData, setCartShowTotals, setLocalStorageVal,
    findElem, findElems, debounce, removeClass
}                                  from "./helpers";
import { ajaxUrlToCart }           from "./vars.js";
import { handleCommonModal }       from "./helpers/modal";
import { runCartModalWindowFuncs } from "./runCartModalWindowFuncs.js";

type TypeJsonResponse = {
    html : string,
    total_products_price : string,
    datalayer_add_cart? : {
        [key : string] : string
    },
    total_products? : string,
    success? : string,
    facebook? : {
        AddToCart? : {
            [key : string] : string
        },
    }
};

const googleTagManagerAction = (json : TypeJsonResponse) : void => {
    if (json.datalayer_add_cart && 'gtag' in window && typeof window.gtag == 'function') {
        window.gtag('event', 'add_to_cart', json.datalayer_add_cart);
    } else {
        console.error('The event add_to_cart not collected!');
    }
};

const updateTotalPriceMarker = (json : TypeJsonResponse) : void => {
    const totalPriceFromCartMarkerEl = <HTMLElement | null>findElem('.total-price-from-cart__price'),
        parentEl = <HTMLElement | null>totalPriceFromCartMarkerEl?.parentElement;

    if (totalPriceFromCartMarkerEl) {
        if (json.total_products) {
            totalPriceFromCartMarkerEl.textContent = json.total_products_price;
            removeClass(parentEl, 'd-none');
        } else {
            addClass(parentEl, 'd-none');
        }
    }
};

const updateCartQuantity = (input : HTMLInputElement) : void => {
    const url : string = ajaxUrlToCart + '.edit',
        form : FormData = newFormData();

    form.set('key', input.dataset.productKey);
    form.set('quantity', input.value);
    form.set('is_oct_buy_one_click', `${isBuyOneClick()}`);

    fetchFunc(url, form)
        .then((json : TypeJsonResponse) : void => {
            if (json.total_products || json.total_products == '0') {
                setCartShowTotals(json.total_products);
            }

            if (json['html']) {
                handleCommonModal({
                    content: json.html,
                    header: (json.success ?? '')
                })
                    .then(() : void => updateTotalPriceMarker(json));
            }

            googleTagManagerAction(json);
        })
        .then(() : void => {
            runCartModalWindowFuncs();
            clearCart();
        });
};

const editCart = () : void => {
    const quantityFields = <HTMLInputElement[] | []>arrayFrom(findElems('[data-product-key]')),
        updateDebounce = debounce(updateCartQuantity, 1000);

    quantityFields.forEach((input : HTMLInputElement) : void => {
        input.oninput = function (this : HTMLInputElement) : void {
            // @ts-ignore
            this.value = this.value.replace(/\D+/gu, '');

            updateDebounce(this);
        };
    });
};

const quantityOperation = () : void => {
    const updateDebounce = debounce(updateCartQuantity, 1000),
        plusBtns = <HTMLElement[] | []>arrayFrom(findElems('[data-quant-plus]')),
        minusBtns = <HTMLElement[] | []>arrayFrom(findElems('[data-quant-minus]')),
        sum = (value : number, inputField : HTMLInputElement, btn : HTMLElement) : void => {
            // @ts-ignore
            let newValue : number = +inputField.value.replace(/\D+/gu, '') + value;

            if (newValue < 1) {
                newValue = 1;
            }

            inputField.value = `${newValue}`;

            if (btn.dataset.notUpdateCart == undefined) {
                updateDebounce(inputField);
            }
        };

    plusBtns.forEach((btn : HTMLElement) : () => void => btn.onclick = () => sum(1, btn.previousElementSibling as HTMLInputElement, btn));
    minusBtns.forEach((btn : HTMLElement) : () => void => btn.onclick = () => sum(-1, btn.nextElementSibling as HTMLInputElement, btn));
};

const removeProduct = () : void => {
    const removeProductBtns = <HTMLElement[] | []>arrayFrom(findElems('[data-remove-prod-cart]')),
        url : string = ajaxUrlToCart + '.remove',
        form : FormData = newFormData();

    removeProductBtns.forEach((btn : HTMLElement) : void => {
        btn.onclick = function (this : HTMLElement) : void {
            form.set('is_oct_buy_one_click', `${isBuyOneClick()}`);
            form.set('key', this.dataset.removeProdCart);

            fetchFunc(url, form)
                .then((json : TypeJsonResponse) : void => {
                    if (json.total_products || json.total_products == '0') {
                        setCartShowTotals(json.total_products);
                    }

                    handleCommonModal({
                        content: json.html,
                        header: (json.success ?? '')
                    })
                        .then(() : void => {
                            updateTotalPriceMarker(json);
                            runCartModalWindowFuncs();
                            clearCart();
                        });
                });
        };
    });
};

const showCartModal = () : void => {
    const showCartBtns = <HTMLElement[] | []>arrayFrom(findElems('.header__cart-content')),
        url = ajaxUrlToCart + '.showModal',
        form = newFormData();

    showCartBtns.forEach((btn : HTMLElement) : void => {
        btn.onclick = () : void => {
            form.set('is_oct_buy_one_click', `${isBuyOneClick()}`);

            fetchFunc(url, form)
                .then((json : TypeJsonResponse) : void => {
                    handleCommonModal({
                        content: json.html
                    })
                        .then(() : void => {
                            runCartModalWindowFuncs();
                            clearCart();
                        })
                });
        };
    });
};

const addToCartEffect = (product : HTMLElement | null) : void => {
    if (!product) {
        return;
    }

    const cartMobile = <HTMLElement>findElem('.header__cart--mobile > button'),
        cartPC = <HTMLElement>findElem('.header__cart--pc > button'),
        productImg = <HTMLElement | null>findElem('.product__main-imgs-img--main, .prods__item-img > img', product);

    if (!productImg) {
        return;
    }

    const productFly = productImg.cloneNode(),
        productFlyWidth = productImg.offsetWidth,
        productFlyHeight = productImg.offsetHeight,
        productFlyTop = productImg.getBoundingClientRect().top,
        productFlyLeft = productImg.getBoundingClientRect().left;

    let cartFlyTop = 0,
        cartFlyLeft = 0;

    if (getComputedStyle(cartMobile.parentElement).display !== 'none') {
        cartFlyTop = cartMobile.getBoundingClientRect().top;
        cartFlyLeft = cartMobile.getBoundingClientRect().left;
    } else {
        cartFlyTop = cartPC.getBoundingClientRect().top;
        cartFlyLeft = cartPC.getBoundingClientRect().left;
    }

    addClass(productFly as HTMLElement, 'fly-img');

    (productFly as HTMLElement).style.cssText = [
        `top:${productFlyTop}px;`,
        `left:${productFlyLeft}px;`,
        `width:${productFlyWidth}px;`,
        `height:${productFlyHeight}px`,
    ].join('');

    document.body.append(productFly);

    productFly.addEventListener('transitionend', () => (productFly as HTMLElement).remove());

    setTimeout(() => {
        (productFly as HTMLElement).style.cssText = [
            `top:${cartFlyTop}px;`,
            `left:${cartFlyLeft + 25}px;`,
            `width:0;`,
            `height:0`,
        ].join('');
    }, 10);
};

function tryTakeOptions(this : HTMLElement, options : HTMLInputElement[] | [], form : FormData) : void {
    if (this.closest('.product__buy')) {
        options.forEach((option : HTMLInputElement) : void => {
            if (option.checked) {
                form.set(`option[${option.dataset.optionCheck}][]`, option.value);
            }
        });
    }
}

function addToCart() {
    const addToCartBtns = <HTMLElement[] | []>arrayFrom(findElems('[data-cart-add]')),
        addToCartOneClick = <HTMLElement[] | []>arrayFrom(findElems('[data-add-one-click]')),
        productQuantity = <HTMLInputElement | null>findElem('[data-product-quantity]'),
        options = <HTMLInputElement[] | []>arrayFrom(findElems('[data-option-check]')),
        url = ajaxUrlToCart + '.add',
        form = newFormData();

    const facebookAction = (json : TypeJsonResponse) : void => {
        if (json.facebook && json.facebook.AddToCart && 'fbq' in window && typeof window.fbq == 'function') {
            window.fbq('track', 'AddToCart', json.facebook.AddToCart);
        } else {
            console.error('fbq() is not a function!');
        }
    };

    addToCartBtns.forEach((btn : HTMLElement) : void => {
        btn.onclick = function (this : HTMLElement) : void {
            addToCartEffect(this.closest('.prods__item'));
            addToCartEffect(findElem('.product__main-imgs-container'));

            setLocalStorageVal('is_oct_buy_one_click', '0');

            tryTakeOptions.call(this, options, form);

            form.set('product_id', this.dataset.cartAdd);
            form.set('quantity', (productQuantity ? productQuantity.value : '1'));

            fetchFunc(url, form)
                .then((json : TypeJsonResponse) : void => {
                    if (json.total_products || json.total_products == '0') {
                        setCartShowTotals(json.total_products);
                    }

                    facebookAction(json);
                    googleTagManagerAction(json);
                    updateTotalPriceMarker(json);
                    clearCart();
                });
        };
    });

    addToCartOneClick.forEach((btn : HTMLElement) : void => {
        btn.onclick = function (this : HTMLElement) : void {
            addToCartEffect(this.closest('.prods__item'));

            setLocalStorageVal('is_oct_buy_one_click', '1');

            tryTakeOptions.call(this, options, form);

            form.set('product_id', this.dataset.addOneClick);
            form.set('quantity', '1');
            form.set('is_oct_buy_one_click', '1');

            fetchFunc(url, form)
                .then((json : TypeJsonResponse) : void => {
                    setTimeout(() : void => {
                        if (json.html) {
                            handleCommonModal({
                                content: json.html,
                                header: (json.success ?? '')
                            })
                                .then(runCartModalWindowFuncs);
                        }

                        updateTotalPriceMarker(json);
                    }, 200);

                    if (json.total_products || json.total_products == '0') {
                        setCartShowTotals(json.total_products);
                    }

                    facebookAction(json);
                    googleTagManagerAction(json);
                    clearCart();
                });
        };
    });
}

const clearCart = () : void => {
    const url : string = ajaxUrlToCart + '.clearCart',
        clearCartBtn = <HTMLElement | null>findElem('.clear-cart-btn');

    if (!clearCartBtn) {
        return;
    }

    clearCartBtn.onclick = async () : Promise<void> => {
        await fetchFunc(url)
            .then((json : TypeJsonResponse) : void => {
                if (json.total_products || json.total_products == '0') {
                    setCartShowTotals(json.total_products);
                }

                handleCommonModal({
                    content: json.html,
                })
                    .then(() : void => {
                        updateTotalPriceMarker(json);
                        runCartModalWindowFuncs();

                        if (json.total_products || json.total_products == '0') {
                            setCartShowTotals(json.total_products);
                        }
                    });
            });
    };

};

export {
    quantityOperation, editCart, addToCart,
    showCartModal, removeProduct
};