import { active_key, is_oct_buy_one_click, spinner_border_key } from "./vars.js";
import { TypeWindowParameters }                                               from "./types/commonTypes";

export const findElem = <T extends HTMLElement>(idOrClassName : string, context : T | Document = document) : T | HTMLElement | null => context.querySelector(idOrClassName),
    findElems = <T extends HTMLElement>(idOrClassName : string, context : T | Document = document) : NodeListOf<T> | NodeListOf<HTMLElement> | null => context.querySelectorAll(idOrClassName),
    arrayFrom = <T>(pseudoArray : ArrayLike<T> | null) : T[] => pseudoArray ? Array.from(pseudoArray) : [],
    addClass = <T extends HTMLElement>(element : T | null, selector : string | string[]) : void | null  => element ? (Array.isArray(selector) ? element.classList.add(...selector) : element.classList.add(selector)) : null,
    removeClass = <T extends HTMLElement>(element : T | null, selector : string | string[]) : void | null  => element ? (Array.isArray(selector) ? element.classList.remove(...selector) : element.classList.remove(selector)) : null,
    toggleClass = <T extends HTMLElement>(element : T | null, selector : string) : boolean => element ? element.classList.toggle(selector) : false,
    toggleActive = (DOMElements : NodeListOf<HTMLElement> | HTMLElement[] = []) : void => arrayFrom(DOMElements).forEach((element : HTMLElement) => toggleClass(element, active_key)),
    setLocalStorageVal = (key : string, value : string) : void => localStorage.setItem(key, value),
    getLocalStorageVal = (key : string) : string | null => localStorage.getItem(key),
    isContainsClass = <T extends HTMLElement>(className : string, element : T | null) : boolean => element ? element.classList.contains(className) : false,
    setCartShowTotals = (totalProducts : string) : void => arrayFrom(findElems('.header__cart-content > span > span')).forEach(cartTotal => cartTotal.textContent = totalProducts),
    redirect = (url : string) : string => location.href = url,
    isBuyOneClick = () : string | number => (getLocalStorageVal(is_oct_buy_one_click) ?? 0),
    newFormData = (form : HTMLElement | null = null) : FormData => form ? new FormData(form as HTMLFormElement) : new FormData(),
    // backLink = () => findElem('.breadcrumbs__link-back')?.addEventListener('click', () => history.back()),
    // @ts-ignore
    // sortLink = () => $('.category__top-select').on('select2:select', e => redirect(e.target.value)), // TODO: need dev new func for links in category page
    removeElement = <T extends HTMLElement>(selector : string, context : T | Document = document) : void => findElem(selector, context)?.remove(),
    takeSpinnerHtml = (selector : string = '') : string => `<div class="${spinner_border_key} ${selector}" role="status"></div>`,
    getParametersFromWindow = () : TypeWindowParameters => 'parameters' in window ? window['parameters'] as TypeWindowParameters : {},
    blockBody = (isBlock : boolean = true) : string => document.body.style.overflow = isBlock ? 'hidden' : '',
    showErrorInConsole = (errorMessage : string) : void => console.error(new Error(errorMessage));

function throttle(func : Function, ms : number) : Function {
    let isThrottled = false,
        savedArgs : IArguments | null,
        savedThis : Function | null;

    function wrapper(this : Function) {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(() : void => {
            isThrottled = false;

            if (savedArgs && savedThis) {
                wrapper.apply(savedThis, savedArgs as any);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

function scrollToTop(anchor : string = '') : void {
    const behavior = 'smooth';

    if (!anchor) {
        scrollTo({
            top: 0,
            behavior
        });
    } else {
        findElem(anchor)?.scrollIntoView({
            behavior
        })
    }
}

function debounce<F extends (...args: any[]) => any>(func: F, delay: number) : (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
    let timeout: ReturnType<typeof setTimeout>;

    return function(this: ThisParameterType<F>, ...args: Parameters<F>) : void {
        const context : ThisParameterType<F> = this;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() : void => {
            func.apply(context, args);
        }, delay);
    };
}

async function fetchFunc(url : string, data : object | FormData = {}, method : string = 'POST', contentType : string = 'application/json;charset=utf-8') : Promise<any> {
    type TypeHeaders = {
        "X-Requested-With" : string,
        contentType? : string,
    };

    const headers : TypeHeaders = {
        'X-Requested-With': 'XMLHttpRequest',
    };

    type TypeOptions = {
        method? : string,
        headers : TypeHeaders,
        body? : FormData | string
    };

    let options : TypeOptions = {
        method,
        headers
    };

    if (method.toUpperCase() === 'GET') {
        options = {headers};
    } else if (!(data instanceof FormData)) {
        options.headers.contentType = contentType;
        options.body = JSON.stringify(data);
    } else if (data instanceof FormData) {
        options.body = data;
    }

    return (await fetch(url, options)).json();
}

function scrollToAnchor(selector : string) : void {
    arrayFrom(findElems(selector)).forEach((link : HTMLLinkElement) => {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            findElem(this.href.replace(/^(.*)(?=#)/, ''))?.scrollIntoView({
                behavior: 'smooth'
            })
        })
    })
}

const toggleLoader = (idOrClassLoader : string, isShowLoader : boolean) : void => {
    const loaderContainer = findElem(idOrClassLoader);

    if (isShowLoader) {
        removeClass(loaderContainer, 'invisible');
        addClass(document.body, 'overflow-hidden');
    } else {
        addClass(loaderContainer, 'invisible');
        removeClass(document.body, 'overflow-hidden');
    }
};

export { throttle, scrollToTop, debounce, fetchFunc, scrollToAnchor, toggleLoader };