import { html_key, lazy_load_key, spinner_border_key } from "./vars.js";
import {
    addClass, fetchFunc, newFormData, removeElement,
    removeClass, takeSpinnerHtml, findElem
}                                                      from "./helpers.ts";
import { lazyLoadImgs }                                from "./lazyLoad.js";
import { wishlist }  from "./wishlist.js";
import { addToCart } from "./cart.ts";

function dynamicLoadContent(dataAnchor, url, selectorForBlockContent = '') {
    const getFirstValue = obj => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return obj[key];
            }
        }

        return undefined;
    };

    const loadContent = (mainBlockContent) => {
        const form = newFormData();
        let contentBlock = mainBlockContent;

        form.set('settings', getFirstValue(mainBlockContent.dataset));

        mainBlockContent.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        addClass(mainBlockContent, [lazy_load_key, 'dynamic-content']);

        fetchFunc(url, form)
            .then(json => {
                if (json[html_key]) {
                    if (selectorForBlockContent.length) {
                        contentBlock = findElem(selectorForBlockContent);
                    }

                    contentBlock.insertAdjacentHTML('beforeend', json[html_key]);

                    lazyLoadImgs(contentBlock);
                    addToCart();
                    wishlist('.prods__wishlist');
                }
            })
            .finally(() => {
                mainBlockContent.removeAttribute(dataAnchor);
                removeClass(mainBlockContent, lazy_load_key);
                removeElement('.' + spinner_border_key, mainBlockContent);
            });
    };

    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadContent(entry.target);
                obs.unobserve(entry.target);
            }
        });
    });

    const blockForContent = findElem(`[${dataAnchor}]`);

    if (blockForContent) {
        observer.observe(blockForContent);
    }
}

export { dynamicLoadContent };